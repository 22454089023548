<template>
  <component v-if="c" :is="c" :key="c.__file"></component>
</template>
<script>
export default {
  data: () => ({
    c: null,    
  }),
  watch : {
      '$store.state.overrideTemplate' (to) {
          this.updateComponent(to);
      }
  },
  methods: {
    updateComponent(template) {
        if(this.$store.state.overrideTemplate !== undefined) {
            import(`@/components/templates/${this.$store.state.templates[this.$store.state.overrideTemplate.toString()]}`).then(module => {
                this.c = module.default;
            })
        } else {
            let templateName = template === this.$store.state.defaultTemplate ? template : this.$store.state.templates[template]
            import(`@/components/templates/${templateName}`).then(module => {
                this.c = module.default;
            })
        }      
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updateComponent(to.meta.template));
  },
  beforeRouteUpdate(to, from, next) {
    this.updateComponent(to.meta.template);
    next();
  }
}
</script>